@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

* {
  box-sizing: border-box;
}

body,
html {
  font-family: "Roboto";
  font-weight: 400;
}

p {
  font-weight: 400;
}

.templateTable{
  min-width: 800px;
}
.credential-table{
  min-width: 800px;
}
.issuer-table{
  min-width: 800px;
}
.logs-table{
  min-width: 800px;
}
.draft{
  min-width: 550px;
}
