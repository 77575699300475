@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  font-size: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
::-webkit-scrollbar {
  display:none;
  /* overflow: hidden; */
}
* {
  box-sizing: border-box;
}

body,
html {
  font-family: "Roboto";
  font-weight: 400;
}

p {
  font-weight: 400;
}

.templateTable{
  min-width: 800px;
}
.credential-table{
  min-width: 800px;
}
.issuer-table{
  min-width: 800px;
}
.logs-table{
  min-width: 800px;
}
.draft{
  min-width: 550px;
}


/* Pre login screens */

.customstyle_preLoginSocial__2XxBZ {
  width: 50px;
  height: 50px;
  padding: 8px 4.7px 6px 5.7px;
  border: solid 0.4px #d5d5d5;
  background-color: #ffffff;
  border-radius: 25px;
  cursor: pointer;
}

.customstyle_primaryInput__34LDK input {
  height: 54px !important;
  padding: 10px !important;
}

.customstyle_primaryInput__34LDK .customstyle_MuiOutlinedInput-root__3YMBH {
  background: transparent !important;
}

.customstyle_primaryInput__34LDK > div {
  background: #fbfbfb !important;
}

.customstyle_primaryInput__34LDK > fieldset {
  border: 1px solid #9f9f9f;
}

/* Custom dropdown style */

.customstyle_dropDown__1ucZG {
  border-radius: 22px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #d5d5d5;
  padding: 9px 35px 8px 15px;
  -webkit-appearance: none;
  background: #f6f6f6 url(/static/media/down-arrow-black.0a003dce.svg) no-repeat
    calc(100% - 15px) center;
  background-size: 13px;
  outline: 0;
  font-size: 14px;
  color: #717171;
  min-width: 140px;
}

.customstyle_dInlineBlock__2wcw9 {
  display: inline-block !important;
}

.customstyle_grayButton__1-bed {
  width: 166px;
  height: 40px;
  border: solid 1px #d5d5d5 !important;
  background-color: #f6f6f6 !important;
}

/* Dashboard screens design */

.customstyle_pageTitle__14AvO {
  margin: 0 0 20px !important;
}

/* Edit Form */
.customstyle_borderNone__3QuvZ fieldset {
  border: none !important;
}

/* spacing classes */

.customstyle_my35__TR-lB {
  margin: 35px 0;
}

.customstyle_my45__2SH1r {
  margin: 45px 0;
}

.customstyle_my30__Rd6tR {
  margin: 30px 0;
}

.customstyle_my20__3NaKT {
  margin: 20px 0;
}

.customstyle_my15__27Ufd {
  margin: 15px 0;
}

.customstyle_my15__27Ufd {
  margin: 10px 0;
}

.customstyle_mb35__34pYE {
  margin-bottom: 35px;
}

.customstyle_mb30__2MHp2 {
  margin-bottom: 30px;
}

.customstyle_mb20__13YJR {
  margin-bottom: 20px;
}

.customstyle_mb15__2UtOp {
  margin-bottom: 15px;
}

.customstyle_mb10__1L36- {
  margin-bottom: 10px;
}

.customstyle_pb30__1uuS3 {
  padding-bottom: 30px;
}

/* table css  */
.customstyle_visuallyHidden__3g1XC {
  border: 0px;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20px;
  width: 1px;
}

.customstyle_tableRoot__GGl8z {
  width: 100%;
  margin: 20px 0;
}

.customstyle_headerRow__aOaJk {
  background-color: #f2f2f2;
  border-radius: 4px;
}

.customstyle_headerRow__aOaJk th {
  color: #616161;
  font-weight: 600;
  font-size: 14px;
}

.customstyle_headerRow__aOaJk th:first-child {
  padding-left: 20px;
}

.customstyle_tablePaper__ZPROg {
  border: 1px solid #cbcbcb;
  border-radius: 12px !important;
  padding: 30px 23px;
}

.customstyle_pagination__bSF_A {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}

.customstyle_showTable__1HS4s {
  color: #354052;
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-right: 10px !important;
}

.customstyle_recordBtn__1wG-1 {
  width: 140px;
  height: 36px;
  padding: 9px 18.7px 8px 18px !important;
  border-radius: 18px !important;
  border: solid 0.8px #d5d5d5 !important;
  background-color: #f5f5f5 !important;
  font-size: 16px !important;
  color: #717171 !important;
  opacity: 0.9;
  font-weight: 500 !important;
}

/* Common Wrapper Headnig */
.customstyle_contentHeadingWrapper__19Ubp {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  justify-content: flex-start;
}

.customstyle_headingLogo__8h_Jn {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: #efefef;
}

.customstyle_headingLogo__8h_Jn img {
  max-width: 40px;
  max-height: 50px;
}

.customstyle_detailsTitle__1VDqV {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.customstyle_detailsTitle__1VDqV h2 {
  overflow: hidden;
  font-weight: 700;
  max-width: 700px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.customstyle_headingIconWrapper__dvI2n {
  cursor: pointer;
  padding: 8px;
  margin: 0 0 0 15px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customstyle_detailsTitle__1VDqV svg {
  color: #fff;
  font-size: 14px;
}

.customstyle_verticalLine__1CnYS {
  margin-left: 5px;
  margin-right: 5px;
}

.customstyle_headingDetails__1_RdJ {
  padding-left: 22px;
  -webkit-user-select: none;
          user-select: none;
}

.customstyle_headingDetails__1_RdJ .customstyle_contactDescription__1DWli {
  color: #616161;
  padding-top: 8px;
}

/* Table Icons Design */
.customstyle_iconsContainer__1PR7l {
  display: flex;
  align-items: center;
}

.customstyle_iconWrapper__2Yj_w {
  cursor: pointer;
  float: left;
  margin-right: 7px;
  padding: 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customstyle_iconCustom__TUcUs {
  font-size: 17px !important;
}

.customstyle_blueColorBackground__20QAK {
  color: rgb(8, 63, 133);
  background-color: #ebf4ff;
}

.customstyle_darkBlueBackground__2Dldz {
  background-color: #083f85;
}

.customstyle_greenColorBackground__u4MlN {
  color: #248a5c;
  background-color: #ceffe9;
}

.customstyle_redColorBackground__gKNcS {
  color: #ff0000;
  background-color: #ffe4e4;
}

.customstyle_fwMedium__d_QsB {
  font-weight: 600 !important;
}

.customstyle_fwBold__3KbHA {
  font-weight: 900 !important;
}

.customstyle_closeIconWrapper__qr9gg {
  position: absolute;
  top: 14px;
  right: 14px;
  opacity: 0.6;
}

/* table css */
.customstyle_currentClass__3Q_ZH {
  min-width: 800px;
}

@media (max-width: 1366px) {
  .customstyle_detailsTitle__1VDqV h2 {
    max-width: 340px;
  }
}

@media (max-width: 1024px) {
  .customstyle_detailsTitle__1VDqV h2 {
    max-width: 230px;
  }
}

@media (max-width: 767px) {
  .customstyle_detailsTitle__1VDqV h2 {
    max-width: 140px;
  }
  .customstyle_templateTitle__3Ib3H {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-break: break-word;
    overflow: hidden;
  }

  .customstyle_headingIconWrapper__dvI2n {
    padding: 5px;
    margin: 0 0 0 10px;
  }

  .customstyle_headingLogo__8h_Jn {
    width: 50px;
    height: 50px;
  }

  .customstyle_headingLogo__8h_Jn img {
    max-width: 30px;
  }

  .customstyle_recordBtn__1wG-1 {
    width: 80px;
    padding: 5px !important;
    font-size: 12px !important;
  }

  .customstyle_recordBtn__1wG-1 svg {
    width: 15px;
  }

  .customstyle_headingDetails__1_RdJ .customstyle_contactDescription__1DWli {
    padding-top: 3px;
  }

  .customstyle_my45__2SH1r {
    margin: 25px 0;
  }

  .customstyle_my35__TR-lB {
    margin: 20px 0;
  }

  .customstyle_my30__Rd6tR {
    margin: 15px 0;
  }

  .customstyle_my20__3NaKT {
    margin: 10px 0;
  }

  .customstyle_my15__27Ufd {
    margin: 10px 0;
  }

  .customstyle_mb35__34pYE {
    margin-bottom: 20px;
  }

  .customstyle_mb30__2MHp2 {
    margin-bottom: 15px;
  }

  .customstyle_mb20__13YJR {
    margin-bottom: 10px;
  }

  .customstyle_mb15__2UtOp {
    margin-bottom: 10px;
  }

  .customstyle_mb10__1L36- {
    margin-bottom: 10px;
  }

  .customstyle_pb30__1uuS3 {
    padding-bottom: 15px;
  }

  .customstyle_primaryInput__34LDK input {
    height: 35px !important;
    padding: 10px !important;
    font-size: 14px !important;
  }

  .customstyle_primaryInput__34LDK .customstyle_MuiOutlinedInput-root__3YMBH {
    /* background: transparent !important; */
  }

  .customstyle_primaryInput__34LDK > div {
    /* background: #fbfbfb !important; */
  }

  .customstyle_primaryInput__34LDK > fieldset {
    /* border: 1px solid #9f9f9f; */
  }
}

.customstyle_animationSpin__3bLbz {
  animation: customstyle_App-logo-spin__1LhjS infinite 4s linear;
}

@keyframes customstyle_App-logo-spin__1LhjS {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

